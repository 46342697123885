import { api } from "@/api";
import { ICOA } from "@/interfaces";
import router from "@/router";
import axios from "axios";
import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import { State } from "../state";
import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetBatchIds,
  commitSetLoggedIn,
  commitSetLogInError,
  commitSetOrderIds,
  commitSetParentCompanies,
  commitSetResult,
  commitSetResults,
  commitSetSampleIds,
  commitSetTestIds,
  commitSetUserProfile,
  commitSetProductTypes,
  commitSetSampleNames,
  commitSetTestTypes,
  commitSetSampleResults,
  commitSetSamplePageImages,
  commitSetSample,
} from "./mutations";
import { AppNotification, MainState } from "./state";
import { loginUrl } from "@/env";
import { checkAuth } from "@/store/plugins";

type MainContext = ActionContext<MainState, State>;

export async function callApi<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: MainContext | any,
  apiCall: () => Promise<T>,
): Promise<T | null> {
  await checkAuth(context);

  let response: T | null = null;
  try {
    response = await apiCall();
  } catch (error) {
    await dispatchCheckApiError(context, error);
  }

  return response;
}

export const actions = {
  async actionLogIn(
    context: MainContext,
    payload: { username: string; password: string },
  ) {
    try {
      const response = await api.logInGetToken(payload.username, payload.password);
      if (response.status === 200) {
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        await dispatchGetUserProfile(context);
        await dispatchRouteLoggedIn(context);
        commitAddNotification(context, { content: "Logged in", color: "success" });
      } else {
        await dispatchLogOut(context);
      }
    } catch (err) {
      commitSetLogInError(context, true);
      await dispatchLogOut(context);
    }
  },
  async actionGetUserProfile(context: MainContext) {
    const response = await callApi(context, () => {
      return api.getMe();
    });
    if (response?.data) {
      commitSetUserProfile(context, response.data);
    }
  },
  async actionCheckLoggedIn(context: MainContext) {
    if (!context.state.isLoggedIn) {
      // Try refreshing the access token, doesn't matter if it fails,
      // we'll wait for a failure in getMe
      try {
        await api.refreshToken();
      } catch (error) {
        ("pass");
      }

      try {
        const response = await api.getMe();
        commitSetLoggedIn(context, true);
        commitSetUserProfile(context, response.data);
        const storedShowAdminView = localStorage.getItem("showAdminView");
        if (
          response.data?.is_admin &&
          (storedShowAdminView === "true" || storedShowAdminView === null)
        ) {
          context.commit("setShowAdminView", true, { root: true });
        }
      } catch (error) {
        await dispatchRemoveLogIn(context);
      }
    }
  },
  async actionRemoveLogIn(context: MainContext) {
    commitSetLoggedIn(context, false);
  },
  async actionLogOut(context: MainContext) {
    await api.logOut();
    await dispatchRemoveLogIn(context);
    await dispatchRouteLogOut(context);
  },
  async actionUserLogOut(context: MainContext) {
    await dispatchLogOut(context);
    commitAddNotification(context, { content: "Logged out", color: "success" });
  },
  actionRouteLogOut() {
    if (loginUrl === "local") {
      router.push("/login");
    } else {
      window.location.href = loginUrl;
    }
  },
  async actionCheckApiError(context: MainContext, payload: unknown) {
    if (axios.isAxiosError(payload)) {
      if (payload.response?.status === 401) {
        await dispatchRemoveLogIn(context);
        await dispatchRouteLogOut(context);
      }
    }
  },
  actionRouteLoggedIn() {
    if (router.currentRoute.path === "/login" || router.currentRoute.path === "/") {
      router.push("/main");
    }
  },
  async removeNotification(
    context: MainContext,
    payload: { notification: AppNotification; timeout: number },
  ) {
    return new Promise<boolean>((resolve) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  },
  async actionGetResults(
    context: MainContext,
    payload: {
      options;
      order_id: string | null;
      sample_id: string | null;
      test_id: string | null;
      company_id: string | null;
      parent_company_id: string | null;
      test_type_id: string | null;
      product_type: string | null;
      batch_id: string | null;
      reported_date_start: string | null;
      reported_date_end: string | null;
      sample_name: string | null;
      show_all_companies: boolean | null;
    },
  ) {
    const options = payload.options;
    const params = {
      limit: options.itemsPerPage,
      skip: options.itemsPerPage * (options.page - 1),
    };
    if (options.sortBy.length > 0) {
      params["sort_by"] = options.sortBy[0];
    }
    if (options.sortDesc.length > 0) {
      params["desc"] = !!options.sortDesc[0];
    }
    if (payload.order_id) {
      params["order_id"] = payload.order_id;
    }
    if (payload.sample_id) {
      params["sample_id"] = payload.sample_id;
    }
    if (payload.test_id) {
      params["test_id"] = payload.test_id;
    }
    if (payload.company_id) {
      params["company_id"] = payload.company_id;
    }
    if (payload.parent_company_id) {
      params["parent_company_id"] = payload.parent_company_id;
    }
    if (payload.test_type_id) {
      params["test_type_id"] = payload.test_type_id;
    }
    if (payload.product_type) {
      params["sample_type"] = payload.product_type;
    }
    if (payload.batch_id) {
      params["batch_id"] = payload.batch_id;
    }
    if (payload.reported_date_start) {
      params["reported_date_start"] = payload.reported_date_start;
    }
    if (payload.reported_date_end) {
      params["reported_date_end"] = payload.reported_date_end;
    }
    if (payload.sample_name) {
      params["sample_name"] = payload.sample_name;
    }
    if (payload.show_all_companies) {
      params["show_all_companies"] = payload.show_all_companies;
    }
    const response = await callApi(context, () => {
      return api.getResults(params);
    });
    if (response?.data) {
      commitSetResults(context, response.data);
    }
  },
  async actionGetParentCompanies(context: MainContext) {
    const response = await callApi(context, () => {
      return api.getParentCompanies();
    });
    if (response?.data) {
      commitSetParentCompanies(context, response.data);
    }
  },
  async actionGetCompanyInfo(
    context: MainContext,
    payload: {
      sample_name?: string;
      order_id?: string;
      test_id?: string;
      sample_id?: string;
    },
  ) {
    const response = await callApi(context, () => {
      return api.getCompanyInfo(payload);
    });
    return response?.data ?? null;
  },
  async actionGetResultTestIds(
    context: MainContext,
    payload: { query: string | null; skip: number; limit: number },
  ) {
    const response = await callApi(context, () => {
      return api.getResultTestIds(payload);
    });
    if (response?.data) {
      commitSetTestIds(context, response.data);
    }
  },
  async actionGetResultSampleIds(
    context: MainContext,
    payload: { query: string | null; skip: number; limit: number },
  ) {
    const response = await callApi(context, () => {
      return api.getResultSampleIds(payload);
    });
    if (response?.data) {
      commitSetSampleIds(context, response.data);
    }
  },
  async actionGetResultOrderIds(
    context: MainContext,
    payload: { query: string | null; skip: number; limit: number },
  ) {
    const response = await callApi(context, () => {
      return api.getResultOrderIds(payload);
    });
    if (response?.data) {
      commitSetOrderIds(context, response.data);
    }
  },
  async actionGetResultBatchIds(
    context: MainContext,
    payload: { query: string | null; skip: number; limit: number },
  ) {
    const response = await callApi(context, () => {
      return api.getResultBatchIds(payload);
    });
    if (response?.data) {
      commitSetBatchIds(context, response.data);
    }
  },
  async actionGetResultProductTypes(
    context: MainContext,
    payload: { query: string | null; skip: number; limit: number },
  ) {
    const response = await callApi(context, () => {
      return api.getResultProductTypes(payload);
    });
    if (response?.data) {
      commitSetProductTypes(context, response.data);
    }
  },
  async actionGetResult(context: MainContext, payload: number) {
    const response = await callApi(context, () => {
      return api.getResult(payload);
    });
    if (response?.data) {
      commitSetResult(context, response.data);
    }
  },
  async actionGetResultSampleNames(
    context: MainContext,
    payload: { query: string | null; skip: number; limit: number },
  ) {
    const response = await callApi(context, () => {
      return api.getResultSampleNames(payload);
    });
    if (response?.data) {
      commitSetSampleNames(context, response.data);
    }
  },
  async actionGetAllTestTypes(
    context: MainContext,
    payload: { query: string | null; skip: number; limit: number },
  ) {
    const response = await callApi(context, () => {
      return api.getAllTestTypes(payload);
    });
    if (response?.data) {
      commitSetTestTypes(context, response.data);
    }
  },
  async actionGetQRCodeImage(context: MainContext, payload: { id: number }) {
    const response = await callApi(context, () => {
      return api.getQRCodeImage(payload.id);
    });
    return response;
  },
  async actionGetCOA(context: MainContext, payload: { id: number }) {
    const response = await callApi(context, () => {
      return api.getCOA(payload.id);
    });
    if (response?.data) {
      return response;
    }
  },
  async actionGetCOAPdf(
    context: MainContext,
    payload: { id: number; version: number | null },
  ) {
    const response = await callApi(context, () => {
      return api.getCOAPdf(payload);
    });
    if (response?.data) {
      return response;
    }
  },
  async actionGetSampleCSV(context: MainContext, payload: { sampleId: string }) {
    const response = await callApi(context, () => {
      return api.getSampleCSV(payload.sampleId);
    });
    if (response?.data) {
      return response;
    }
  },
  async actionGetResultCSV(context: MainContext, payload: { id: number }) {
    const response = await callApi(context, () => {
      return api.getResultCSV(payload.id);
    });
    if (response?.data) {
      return response;
    }
  },
  async actionUpdateCOA(context: MainContext, payload: ICOA) {
    const response = await callApi(context, () => {
      return api.updateCOA(payload.id, payload);
    });
    if (response?.data) {
      return response;
    }
  },
  async actionGetResultChildCompanies(context: MainContext, resultId: number) {
    const response = await callApi(context, () => {
      return api.getResultChildCompanies(resultId);
    });
    if (response?.data) {
      return response;
    }
  },
  async actionGetSampleChildCompanies(context: MainContext, sampleId: string) {
    const response = await callApi(context, () => {
      return api.getSampleChildCompanies(sampleId);
    });
    if (response?.data) {
      return response;
    }
  },
  async actionGetSample(context: MainContext, sample_id: string) {
    const response = await callApi(context, () => {
      return api.getSample(sample_id);
    });
    if (response?.data) {
      commitSetSample(context, response.data);
    }
  },
  async actionGetSamplePageImages(context: MainContext, sample_id: string) {
    const response = await callApi(context, () => {
      return api.getSampleImagesForSampleId(sample_id);
    });
    if (response?.data) {
      commitSetSamplePageImages(context, {
        sample_id: sample_id,
        images: response.data,
      });
    }
  },
  async actionGetSamplePageResults(
    context: MainContext,
    payload: {
      options;
      sample_id: string;
    },
  ) {
    const options = payload.options;
    const params = {
      limit: options.itemsPerPage,
      skip: options.itemsPerPage * (options.page - 1),
    };
    if (options.sortBy && options.sortBy.length > 0) {
      params["sort_by"] = options.sortBy[0];
    }
    if (options.sortDesc && options.sortDesc.length > 0) {
      params["desc"] = !!options.sortDesc[0];
    }
    params["sample_id"] = payload.sample_id;
    const response = await callApi(context, () => {
      return api.getResultsForSample(params);
    });
    if (response?.data) {
      commitSetSampleResults(context, response.data);
    }
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { dispatch } = getStoreAccessors<MainState | any, State>("");

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchGetResults = dispatch(actions.actionGetResults);
export const dispatchGetParentCompanies = dispatch(actions.actionGetParentCompanies);
export const dispatchGetCompanyInfo = dispatch(actions.actionGetCompanyInfo);
export const dispatchGetResultTestIds = dispatch(actions.actionGetResultTestIds);
export const dispatchGetResultSampleIds = dispatch(actions.actionGetResultSampleIds);
export const dispatchGetResultOrderIds = dispatch(actions.actionGetResultOrderIds);
export const dispatchGetResultBatchIds = dispatch(actions.actionGetResultBatchIds);
export const dispatchGetResult = dispatch(actions.actionGetResult);
export const dispatchGetResultProductTypes = dispatch(
  actions.actionGetResultProductTypes,
);
export const dispatchGetResultSampleNames = dispatch(
  actions.actionGetResultSampleNames,
);
export const dispatchGetAllTestTypes = dispatch(actions.actionGetAllTestTypes);
export const dispatchGetQRCodeImage = dispatch(actions.actionGetQRCodeImage);
export const dispatchGetCOAObject = dispatch(actions.actionGetCOA);
export const dispatchGetCOAPdf = dispatch(actions.actionGetCOAPdf);
export const dispatchGetSampleCSV = dispatch(actions.actionGetSampleCSV);
export const dispatchGetResultCSV = dispatch(actions.actionGetResultCSV);
export const dispatchUpdateCOA = dispatch(actions.actionUpdateCOA);
export const dispatchGetResultChildCompanies = dispatch(
  actions.actionGetResultChildCompanies,
);
export const dispatchGetSampleChildCompanies = dispatch(
  actions.actionGetSampleChildCompanies,
);
export const dispatchGetSample = dispatch(actions.actionGetSample);
export const dispatchGetSamplePageImages = dispatch(actions.actionGetSamplePageImages);
export const dispatchGetSampleResults = dispatch(actions.actionGetSamplePageResults);

import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  adminUsers: (state: AdminState) => state.users,
  adminOneUser: (state: AdminState) => (userId: number) => {
    const filteredUsers = state.users.filter((user) => user.id === userId);
    if (filteredUsers.length > 0) {
      return { ...filteredUsers[0] };
    }
  },
  adminParentCompany: (state: AdminState) => state.parentCompany,
  adminParentCompanies: (state: AdminState) => state.parentCompanies,
  adminParentCompaniesPagination: (state: AdminState) =>
    state.parentCompaniesPagination,
  adminChildCompany: (state: AdminState) => state.childCompany,
  adminChildCompanies: (state: AdminState) => state.childCompanies,
  adminChildCompaniesPagination: (state: AdminState) => state.childCompaniesPagination,
  showAdminView: (state: AdminState) => state.showAdminView,
  userImages: (state: AdminState) => state.userImages,
  serverImages: (state: AdminState) => state.serverImages,
};

const { read } = getStoreAccessors<AdminState, State>("");

export const readAdminOneUser = read(getters.adminOneUser);
export const readAdminUsers = read(getters.adminUsers);
export const readAdminParentCompany = read(getters.adminParentCompany);
export const readAdminParentCompanies = read(getters.adminParentCompanies);
export const readAdminParentCompaniesPagination = read(
  getters.adminParentCompaniesPagination,
);
export const readAdminChildCompany = read(getters.adminChildCompany);
export const readAdminChildCompanies = read(getters.adminChildCompanies);
export const readAdminChildCompaniesPagination = read(
  getters.adminChildCompaniesPagination,
);
export const readShowAdminView = read(getters.showAdminView);
export const readUserImages = read(getters.userImages);
export const readServerImages = read(getters.serverImages);

import { api } from "@/api";
import { ActionContext } from "vuex";
import {
  ISampleImage,
  ISampleImageEdit,
  IUserProfileCreate,
  IUserProfileUpdate,
} from "@/interfaces";
import { State } from "../state";
import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { commitSetUsers, commitSetUser, commitSetServerImages } from "./mutations";
import { callApi } from "../main/actions";
import { commitAddNotification, commitRemoveNotification } from "../main/mutations";
import {
  commitSetAdminParentCompanies,
  commitSetAdminChildCompanies,
} from "../admin/mutations";

type MainContext = ActionContext<AdminState, State>;

export const actions = {
  async actionGetUsers(context: MainContext) {
    const response = await callApi(context, () => {
      return api.getUsers();
    });
    if (response?.data) {
      commitSetUsers(context, response.data);
    }
  },
  async actionUpdateUser(
    context: MainContext,
    payload: { id: number; user: IUserProfileUpdate },
  ) {
    const loadingNotification = { content: "saving", showProgress: true };
    commitAddNotification(context, loadingNotification);
    const response = await callApi(context, () => {
      return Promise.all([
        api.updateUser(payload.id, payload.user),
        new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
      ]);
    });
    if (response?.[0].data) {
      commitSetUser(context, response[0].data);
    }
    commitRemoveNotification(context, loadingNotification);
    commitAddNotification(context, {
      content: "User successfully updated",
      color: "success",
    });
  },
  async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
    const loadingNotification = { content: "saving", showProgress: true };
    commitAddNotification(context, loadingNotification);
    const response = await callApi(context, () => {
      return Promise.all([
        api.createUser(payload),
        new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
      ]);
    });
    if (response?.[0].data) {
      commitSetUser(context, response[0].data);
    }
    commitRemoveNotification(context, loadingNotification);
    commitAddNotification(context, {
      content: "User successfully created",
      color: "success",
    });
  },
  async actionGetAdminParentCompanies(
    context: MainContext,
    payload: { query: string | null; skip: number; limit: number },
  ) {
    const response = await callApi(context, () => {
      return api.getAllParentCompanies(payload);
    });
    if (response?.data) {
      commitSetAdminParentCompanies(context, response.data);
    }
  },
  async actionGetAdminChildCompanies(
    context: MainContext,
    payload: { query: string | null; skip: number; limit: number },
  ) {
    const response = await callApi(context, () => {
      return api.getAllChildCompanies(payload);
    });
    if (response?.data) {
      commitSetAdminChildCompanies(context, response.data);
    }
  },
  async actionPostImage(
    context: MainContext,
    payload: { test_id: string; file: File },
  ) {
    const response = await callApi(context, () => {
      return api.postSampleImage(payload.test_id, payload.file);
    });
    if (response?.data) {
      return response;
    }
  },
  async actionGetImagesForSample(context: MainContext, payload: { sample_id: string }) {
    const response = await callApi(context, () => {
      return api.getSampleImagesForSampleId(payload.sample_id);
    });
    if (response?.data) {
      const sampleImagesEdit: ISampleImageEdit[] = [];
      for (const image of response.data) {
        const r = await api.getSampleImage(image.id);
        if (r) {
          const file: File = r.data;
          sampleImagesEdit.push({
            file: file,
            data: image,
            url: URL.createObjectURL(file),
          });
        }
      }
      commitSetServerImages(context, sampleImagesEdit);
    }
  },
  async actionUpdateSampleImage(context: MainContext, payload: ISampleImage) {
    const response = await callApi(context, () => {
      return api.updateSampleImage(payload);
    });
    if (response?.data) {
      return response;
    }
  },
  async actionGetSampleForTestId(context: MainContext, test_id: string) {
    const response = await callApi(context, () => {
      return api.getSampleFromTestId(test_id);
    });
    if (response?.data) {
      return response;
    }
  },
};

const { dispatch } = getStoreAccessors<AdminState, State>("");

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchGetAdminParentCompanies = dispatch(
  actions.actionGetAdminParentCompanies,
);
export const dispatchGetAdminChildCompanies = dispatch(
  actions.actionGetAdminChildCompanies,
);
export const dispatchPostImage = dispatch(actions.actionPostImage);
export const dispatchGetImagesForSample = dispatch(actions.actionGetImagesForSample);
export const dispatchUpdateSampleImage = dispatch(actions.actionUpdateSampleImage);
export const dispatchGetSampleForTestId = dispatch(actions.actionGetSampleForTestId);

import {
  IUserProfile,
  IParentCompany,
  IParentCompaniesWrapper,
  ICompany,
  ICompaniesWrapper,
  ISampleImageEdit,
} from "@/interfaces";
import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setUsers(state: AdminState, payload: IUserProfile[]) {
    state.users = payload;
  },
  setUser(state: AdminState, payload: IUserProfile) {
    const users = state.users.filter((user: IUserProfile) => user.id !== payload.id);
    users.push(payload);
    state.users = users;
  },
  setAdminParentCompany(state: AdminState, payload: IParentCompany | null) {
    state.parentCompany = payload;
  },
  setAdminParentCompanies(state: AdminState, payload: IParentCompaniesWrapper) {
    state.parentCompaniesPagination = payload.pagination;
    if (payload.pagination.offset > 0) {
      state.parentCompanies.push(...payload.parent_companies);
    } else {
      state.parentCompanies = payload.parent_companies;
    }
  },
  setAdminChildCompany(state: AdminState, payload: ICompany | null) {
    state.childCompany = payload;
  },
  setAdminChildCompanies(state: AdminState, payload: ICompaniesWrapper) {
    state.childCompaniesPagination = payload.pagination;
    if (payload.pagination.offset > 0) {
      state.childCompanies.push(...payload.companies);
    } else {
      state.childCompanies = payload.companies;
    }
  },
  setShowAdminView(state: AdminState, payload: boolean) {
    localStorage.setItem("showAdminView", payload.toString());
    state.showAdminView = payload;
  },
  setUserImages(state: AdminState, payload: File[]) {
    state.userImages = payload;
  },
  setServerImages(state: AdminState, payload: ISampleImageEdit[]) {
    state.serverImages = payload;
  },
  setArchiveServerImage(state: AdminState, payload: { id: number; archived: boolean }) {
    const index = state.serverImages.findIndex((x) => x.data.id === payload.id);
    state.serverImages[index].data.archived = payload.archived;
  },
};

const { commit } = getStoreAccessors<AdminState, State>("");

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);

export const commitSetAdminParentCompany = commit(mutations.setAdminParentCompany);
export const commitSetAdminParentCompanies = commit(mutations.setAdminParentCompanies);
export const commitSetAdminChildCompany = commit(mutations.setAdminChildCompany);
export const commitSetAdminChildCompanies = commit(mutations.setAdminChildCompanies);
export const commitSetShowAdminView = commit(mutations.setShowAdminView);
export const commitSetUserImages = commit(mutations.setUserImages);
export const commitSetServerImages = commit(mutations.setServerImages);
export const commitSetArchiveServerImage = commit(mutations.setArchiveServerImage);

import axios from "axios";
import { apiUrl } from "@/env";
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
  IResultsWrapper,
  IParentCompany,
  ICOA,
  IColumnValuesWrapper,
  IResult,
  ITemplate,
  ITestType,
  ITemplateDetails,
  ICOACreateAssignSection,
  ITemplateCreate,
  ITestTypesWrapper,
  IParentCompaniesWrapper,
  ICompaniesWrapper,
  ISampleImage,
  ICompany,
  IResultsWrapperWithCoas,
  ICompanyInfo,
} from "./interfaces";

axios.defaults.withCredentials = true;

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async logOut() {
    return axios.get(`${apiUrl}/api/v1/logout`);
  },
  async refreshToken() {
    return axios.get(`${apiUrl}/api/v1/login/refresh-token`);
  },
  async getMe() {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`);
  },
  async updateMe(data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data);
  },
  async getUsers() {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`);
  },
  async updateUser(userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data);
  },
  async createUser(data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data);
  },
  async getResults(params) {
    return axios.get<IResultsWrapper>(`${apiUrl}/api/v1/results/`, {
      params: params,
    });
  },
  async getResultsForSample(params) {
    return axios.get<IResultsWrapperWithCoas>(
      `${apiUrl}/api/v1/results/sample/${params.sample_id}`,
      {
        params: params,
      },
    );
  },
  async getParentCompanies() {
    return axios.get<IParentCompany[]>(`${apiUrl}/api/v1/companies/`, {});
  },
  async getCompanyInfo(params: {
    sample_name?: string;
    order_id?: string;
    test_id?: string;
    sample_id?: string;
  }) {
    return axios.get<ICompanyInfo>(`${apiUrl}/api/v1/companies/info/`, { params });
  },
  async getAllParentCompanies(params: {
    query: string | null;
    skip: number;
    limit: number;
  }) {
    return axios.get<IParentCompaniesWrapper>(
      `${apiUrl}/api/v1/companies/parent/search/`,
      {
        params: params,
      },
    );
  },
  async getAllChildCompanies(params: {
    query: string | null;
    skip: number;
    limit: number;
  }) {
    return axios.get<ICompaniesWrapper>(`${apiUrl}/api/v1/companies/child/search/`, {
      params: params,
    });
  },
  async getResultChildCompanies(resultId: number) {
    return axios.get<ICompany[]>(`${apiUrl}/api/v1/companies/child/result/${resultId}`);
  },
  async getSampleChildCompanies(sampleId: string) {
    return axios.get<ICompany[]>(`${apiUrl}/api/v1/companies/child/sample/${sampleId}`);
  },
  async getResultTestIds(params: {
    query: string | null;
    skip: number;
    limit: number;
  }) {
    return axios.get<IColumnValuesWrapper>(`${apiUrl}/api/v1/results/test_ids/`, {
      params: params,
    });
  },
  async getResultSampleIds(params: {
    query: string | null;
    skip: number;
    limit: number;
  }) {
    return axios.get<IColumnValuesWrapper>(`${apiUrl}/api/v1/results/sample_ids/`, {
      params: params,
    });
  },
  async getResultOrderIds(params: {
    query: string | null;
    skip: number;
    limit: number;
  }) {
    return axios.get<IColumnValuesWrapper>(`${apiUrl}/api/v1/results/order_ids/`, {
      params: params,
    });
  },
  async getResultBatchIds(params: {
    query: string | null;
    skip: number;
    limit: number;
  }) {
    return axios.get<IColumnValuesWrapper>(`${apiUrl}/api/v1/results/batch_ids/`, {
      params: params,
    });
  },
  async getResultProductTypes(params: {
    query: string | null;
    skip: number;
    limit: number;
  }) {
    return axios.get<IColumnValuesWrapper>(`${apiUrl}/api/v1/results/sample_types/`, {
      params: params,
    });
  },
  async getResultSampleNames(params: {
    query: string | null;
    skip: number;
    limit: number;
  }) {
    return axios.get<IColumnValuesWrapper>(`${apiUrl}/api/v1/results/sample_names/`, {
      params: params,
    });
  },
  async getAllTestTypes(params: { query: string | null; skip: number; limit: number }) {
    return axios.get<ITestTypesWrapper>(`${apiUrl}/api/v1/test_types/`, {
      params: params,
    });
  },
  async getResult(id: number) {
    return axios.get<IResult>(`${apiUrl}/api/v1/results/${id}`, {});
  },
  async getCOA(id: number) {
    return axios.get<ICOA>(`${apiUrl}/api/v1/coas/${id}`, {});
  },
  async getCOAs(resultId: number) {
    return axios.get<ICOA[]>(`${apiUrl}/api/v1/coas/`, {
      params: { result_id: resultId },
    });
  },
  async getMultiTestCOAs(sampleId: string) {
    return axios.get<ICOA[]>(`${apiUrl}/api/v1/coas/`, {
      params: { sample_id: sampleId, multi_result_only: true },
    });
  },
  async getCOAPdf(payload: { id: number; version: number | null }) {
    return axios.get(`${apiUrl}/api/v1/coas/${payload.id}/pdf`, {
      responseType: "blob",
      params: {
        version: payload.version,
      },
    });
  },
  async getSampleCSV(sample_id: string) {
    return axios.get(`${apiUrl}/api/v1/results/sample/${sample_id}/csv`, {
      responseType: "blob",
    });
  },
  async getResultCSV(id: number) {
    return axios.get(`${apiUrl}/api/v1/results/${id}/csv`, {
      responseType: "blob",
    });
  },
  async updateCOA(id: number, payload: ICOA) {
    return axios.put<ICOA>(`${apiUrl}/api/v1/coas/${id}`, payload, {});
  },
  async getCOAPreview(payload: {
    template_id: number | null;
    result_id: number | null;
    sample_id: string | null;
    coa_sections: ICOACreateAssignSection[] | null;
  }) {
    return axios.post(`${apiUrl}/api/v1/coas/preview/`, payload, {
      responseType: "blob",
    });
  },
  async createCOA(payload: {
    template_id: number | null;
    result_id: number | null;
    sample_id: string | null;
    name: string;
    published: boolean;
    coa_sections: ICOACreateAssignSection[];
  }) {
    return axios.post<ICOA>(`${apiUrl}/api/v1/coas/`, payload, {});
  },
  async getTemplates(result_id: number) {
    return axios.get<ITemplate[]>(`${apiUrl}/api/v1/templates/`, {
      params: { result_id: result_id },
    });
  },
  async getTemplateDetails(template_id: number) {
    return axios.get<ITemplateDetails>(`${apiUrl}/api/v1/templates/${template_id}`, {});
  },
  async createTemplate(payload: ITemplateCreate) {
    return axios.post<ITemplateDetails>(`${apiUrl}/api/v1/templates/`, payload, {});
  },
  async updateTemplate(id: number, payload: ITemplateCreate) {
    return axios.put<ITemplateDetails>(`${apiUrl}/api/v1/templates/${id}`, payload, {});
  },
  async getDefaultTemplate(testTypeId: number) {
    return axios.get<ITemplateDetails>(`${apiUrl}/api/v1/templates/default/`, {
      params: { test_type_id: testTypeId },
    });
  },
  async getDefaultMultiResultTemplate(sampleId: string) {
    return axios.get<ITemplateDetails>(
      `${apiUrl}/api/v1/templates/default/multi_result`,
      {
        params: { sample_id: sampleId },
      },
    );
  },
  async getTestType(test_type_id: number) {
    return axios.get<ITestType>(`${apiUrl}/api/v1/test_types/${test_type_id}`, {});
  },
  async getMultiResultTestType(sampleId: string) {
    return axios.get<ITestType>(`${apiUrl}/api/v1/test_types/multi_result/`, {
      params: { sample_id: sampleId },
    });
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
    });
  },
  async postSampleImage(test_id: string, file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return axios.post(`${apiUrl}/api/v1/sample_images/test/${test_id}`, formData, {});
  },
  async getSampleImagesForSampleId(sample_id: string) {
    return axios.get(`${apiUrl}/api/v1/sample_images/sample/${sample_id}`, {});
  },
  async updateSampleImage(payload: ISampleImage) {
    return axios.put(`${apiUrl}/api/v1/sample_images/${payload.id}`, payload, {});
  },
  async getQRCodeImage(id: number) {
    return axios.get(`${apiUrl}/api/v1/coas/${id}/qrcode`, {
      responseType: "blob",
    });
  },
  async getSampleImage(id: number) {
    return axios.get(`${apiUrl}/api/v1/sample_images/${id}/image`, {
      responseType: "blob",
    });
  },
  async getSampleFromTestId(test_id: string) {
    return axios.get(`${apiUrl}/api/v1/samples/test/${test_id}`);
  },
  async getSample(sample_id: string) {
    return axios.get(`${apiUrl}/api/v1/samples/${sample_id}`, {});
  },
};

const env = process.env.VUE_APP_ENV;

let envApiUrl = "";
let envLoginUrl = "";

if (env === "production") {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
  envLoginUrl = "https://orders.botanacor.com";
} else if (env === "staging") {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
  envLoginUrl = "http://testorders.botanacor.com";
} else {
  envApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}`;
  envLoginUrl = "local";
}

export const apiUrl = envApiUrl;
export const appName = process.env.VUE_APP_NAME;
export const loginUrl = envLoginUrl;
export const isProd = env === "production";

import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { AdminState } from "./state";

const getDefaultState: () => AdminState = () => ({
  users: [],
  showAdminView: false,
  childCompany: null,
  childCompanies: [],
  childCompaniesPagination: { total: 0, offset: 0 },
  parentCompany: null,
  parentCompanies: [],
  parentCompaniesPagination: { total: 0, offset: 0 },
  userImages: [],
  serverImages: [],
});

export const getAdminModule = () => ({
  state: getDefaultState(),
  mutations,
  actions,
  getters,
});

import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { MainState } from "./state";

const getDefaultState: () => MainState = () => ({
  isLoggedIn: null,
  logInError: false,
  userProfile: null,
  dashboardMiniDrawer: false,
  dashboardShowDrawer: true,
  notifications: [],
  options: { page: 1 },
  results: [],
  sampleResults: [],
  sampleResultsPagination: { total: 0, offset: 0 },
  sampleResultsOptions: { page: 1 },
  resultsPagination: { total: 0, offset: 0 },
  parentCompanies: [],
  orderId: null,
  sampleId: null,
  batchId: null,
  testId: null,
  testIds: [],
  testIdsPagination: { total: 0, offset: 0 },
  sampleIds: [],
  sampleIdsPagination: { total: 0, offset: 0 },
  orderIds: [],
  orderIdsPagination: { total: 0, offset: 0 },
  batchIds: [],
  batchIdsPagination: { total: 0, offset: 0 },
  result: null,
  sample: null,
  showNavDrawer: false,
  productType: null,
  productTypes: [],
  productTypesPagination: { total: 0, offset: 0 },
  reportedDates: [],
  sampleName: null,
  sampleNames: [],
  sampleNamesPagination: { total: 0, offset: 0 },
  testType: null,
  testTypes: [],
  testTypesPagination: { total: 0, offset: 0 },
  samplePageImages: { sample_id: "", images: [] },
});

export const getMainModule = () => ({
  state: getDefaultState(),
  mutations,
  actions,
  getters,
});

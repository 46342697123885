import Vue from "vue";
import Router from "vue-router";

import RouterComponent from "./components/RouterComponent.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      component: () => import(/* webpackChunkName: "start" */ "./views/main/Start.vue"),
      children: [
        {
          path: "login",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ "./views/Login.vue"),
        },
        {
          path: "main",
          component: () =>
            import(/* webpackChunkName: "main" */ "./views/main/Main.vue"),
          children: [
            {
              path: "dashboard/:companyType/:id",
              name: "dashboard",
              component: () =>
                import(
                  /* webpackChunkName: "main-dashboard" */ "./views/main/Dashboard.vue"
                ),
            },
            {
              path: "sample/:sampleId",
              component: () =>
                import(
                  /* webpackChunkName: "main-sample-start" */ "./views/main/SampleStart.vue"
                ),
              children: [
                {
                  path: "/",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-sample-details" */ "./views/main/SampleDetails.vue"
                    ),
                  children: [
                    {
                      path: "/",
                      name: "sample-landing-page",
                      component: () =>
                        import(
                          /* webpackChunkName: "main-sample-landing-page" */ "./views/main/SampleLandingPage.vue"
                        ),
                    },
                    {
                      path: "coa/:coaId",
                      name: "coa-sample",
                      component: () =>
                        import(
                          /* webpackChunkName: "main-coa-details" */ "./views/main/CoaDetails.vue"
                        ),
                    },
                  ],
                },
                {
                  path: "create-coa",
                  name: "create-coa-sample",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-create-coa" */ "./views/main/CoaCreate.vue"
                    ),
                },
              ],
            },
            {
              path: "result/:id",
              component: () =>
                import(
                  /* webpackChunkName: "main-result-start" */ "./views/main/ResultStart.vue"
                ),
              children: [
                {
                  path: "/",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-result-details" */ "./views/main/ResultDetails.vue"
                    ),
                  children: [
                    {
                      path: "/",
                      name: "result",
                      component: () =>
                        import(
                          /* webpackChunkName: "main-result-landing-page" */ "./views/main/ResultLandingPage.vue"
                        ),
                    },
                    {
                      path: "coa/:coaId",
                      name: "coa",
                      component: () =>
                        import(
                          /* webpackChunkName: "main-coa-details" */ "./views/main/CoaDetails.vue"
                        ),
                    },
                  ],
                },
                {
                  path: "create-coa",
                  name: "create-coa",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-create-coa" */ "./views/main/CoaCreate.vue"
                    ),
                },
              ],
            },
            {
              path: "profile",
              component: RouterComponent,
              redirect: "profile/view",
              children: [
                {
                  path: "view",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile" */ "./views/main/profile/UserProfile.vue"
                    ),
                },
              ],
            },
            {
              path: "admin",
              component: () =>
                import(
                  /* webpackChunkName: "main-admin" */ "./views/main/admin/Admin.vue"
                ),
              redirect: "admin/users/all",
              children: [
                {
                  path: "users",
                  redirect: "users/all",
                },
                {
                  path: "users/all",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users" */ "./views/main/admin/AdminUsers.vue"
                    ),
                },
                {
                  path: "image-upload",
                  name: "imageUpload",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-image-upload" */ "./views/main/admin/ImageUpload.vue"
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/permission-error",
      component: () =>
        import(
          /* webpackChunkName: "permission-error" */ "./views/PermissionError.vue"
        ),
    },
    {
      path: "/*",
      redirect: "/",
    },
  ],
});

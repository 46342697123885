export const debounce = (func, wait) => {
  let timeout;

  return async function executedFunction(...args) {
    const later = async () => {
      clearTimeout(timeout);
      await func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export function getCookie(name: string) {
  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = document.cookie.split(";");
  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split("=");
    /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
    if (name == cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }
  // Return null if not found
  return null;
}

import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

import { getMainModule } from "./main";
import { State } from "./state";
import { getAdminModule } from "./admin";

Vue.use(Vuex);

export const createStoreOptions = () => ({
  modules: {
    main: getMainModule(),
    admin: getAdminModule(),
  },
});

const storeOptions: StoreOptions<State> = createStoreOptions();

export const store = new Vuex.Store<State>(storeOptions);

export default store;

import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#00b098",
        secondary: "#ffe358",
        dark: "#2e3192",
        light: "3a86ff",
        accent: "#3a8fff",
        pink: "#f26d7d",
        dark_red: "#790431",
        dark_blue: "#1b1464",
        light_grey: "#f7f7f7",
        error: "#b71c1c",
        background: "#82193b",
      },
    },
  },
});

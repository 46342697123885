import { getCookie } from "@/utils";
import { readIsLoggedIn } from "./main/getters";
import { api } from "@/api";

export let tokenRefreshPromise: Promise<any> | null = null;

export const checkAuth = async (context) => {
  /**
   * Call this at the start of any action that requires authenticated API access
   */
  const accessTokenExpiration = getCookie("subCookieExpiration");
  if (
    readIsLoggedIn(context) &&
    accessTokenExpiration &&
    +accessTokenExpiration * 1000 - new Date().getTime() < 300000
  ) {
    try {
      if (tokenRefreshPromise) {
        await tokenRefreshPromise;
      } else {
        tokenRefreshPromise = api.refreshToken();
        await tokenRefreshPromise;
        tokenRefreshPromise = null;
      }
    } catch (error) {
      ("pass");
    }
  }
};

import { MainState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  hasAdminAccess: (state: MainState) => {
    return (
      state.userProfile && state.userProfile.is_admin && state.userProfile.is_active
    );
  },
  loginError: (state: MainState) => state.logInError,
  dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
  dashboardMiniDrawer: (state: MainState) => state.dashboardMiniDrawer,
  userProfile: (state: MainState) => state.userProfile,
  isLoggedIn: (state: MainState) => state.isLoggedIn,
  firstNotification: (state: MainState) =>
    state.notifications.length > 0 && state.notifications[0],
  options: (state: MainState) => state.options,
  results: (state: MainState) => state.results,
  resultsPagination: (state: MainState) => state.resultsPagination,
  parentCompanies: (state: MainState) => state.parentCompanies,
  orderId: (state: MainState) => state.orderId,
  sampleId: (state: MainState) => state.sampleId,
  batchId: (state: MainState) => state.batchId,
  testId: (state: MainState) => state.testId,
  testIds: (state: MainState) => state.testIds,
  testIdsPagination: (state: MainState) => state.testIdsPagination,
  sampleIds: (state: MainState) => state.sampleIds,
  sampleIdsPagination: (state: MainState) => state.sampleIdsPagination,
  orderIds: (state: MainState) => state.orderIds,
  orderIdsPagination: (state: MainState) => state.orderIdsPagination,
  batchIds: (state: MainState) => state.batchIds,
  batchIdsPagination: (state: MainState) => state.batchIdsPagination,
  result: (state: MainState) => state.result,
  sample: (state: MainState) => state.sample,
  showNavDrawer: (state: MainState) => state.showNavDrawer,
  productType: (state: MainState) => state.productType,
  productTypes: (state: MainState) => state.productTypes,
  productTypesPagination: (state: MainState) => state.productTypesPagination,
  reportedDates: (state: MainState) => state.reportedDates,
  sampleName: (state: MainState) => state.sampleName,
  sampleNames: (state: MainState) => state.sampleNames,
  sampleNamesPagination: (state: MainState) => state.sampleNamesPagination,
  testType: (state: MainState) => state.testType,
  testTypes: (state: MainState) => state.testTypes,
  testTypesPagination: (state: MainState) => state.testTypesPagination,
  sampleResults: (state: MainState) => state.sampleResults,
  sampleResultsOptions: (state: MainState) => state.sampleResultsOptions,
  samplePageimages: (state: MainState) => state.samplePageImages,
  sampleResultsPagination: (state: MainState) => state.sampleResultsPagination,
};

const { read } = getStoreAccessors<MainState, State>("");

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer);
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readUserProfile = read(getters.userProfile);
export const readFirstNotification = read(getters.firstNotification);
export const readOptions = read(getters.options);
export const readResults = read(getters.results);
export const readResultsPagination = read(getters.resultsPagination);
export const readParentCompanies = read(getters.parentCompanies);
export const readOrderId = read(getters.orderId);
export const readSampleId = read(getters.sampleId);
export const readBatchId = read(getters.batchId);
export const readTestId = read(getters.testId);
export const readTestIds = read(getters.testIds);
export const readTestIdsPagination = read(getters.testIdsPagination);
export const readSampleIds = read(getters.sampleIds);
export const readSampleIdsPagination = read(getters.sampleIdsPagination);
export const readOrderIds = read(getters.orderIds);
export const readOrderIdsPagination = read(getters.orderIdsPagination);
export const readBatchIds = read(getters.batchIds);
export const readBatchIdsPagination = read(getters.batchIdsPagination);
export const readResult = read(getters.result);
export const readSample = read(getters.sample);
export const readShowNavDrawer = read(getters.showNavDrawer);
export const readProductType = read(getters.productType);
export const readProductTypes = read(getters.productTypes);
export const readProductTypesPagination = read(getters.productTypesPagination);
export const readReportedDates = read(getters.reportedDates);
export const readSampleName = read(getters.sampleName);
export const readSampleNames = read(getters.sampleNames);
export const readSampleNamesPagination = read(getters.sampleNamesPagination);
export const readTestType = read(getters.testType);
export const readTestTypes = read(getters.testTypes);
export const readTestTypesPagination = read(getters.testTypesPagination);
export const readSampleResults = read(getters.sampleResults);
export const readSampleResultsOptions = read(getters.sampleResultsOptions);
export const readSamplePageImages = read(getters.samplePageimages);
export const readSampleResultsPagination = read(getters.sampleResultsPagination);

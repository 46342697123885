import {
  IParentCompany,
  IResultsWrapper,
  IColumnValuesWrapper,
  IUserProfile,
  IResult,
  ISample,
  ITestType,
  ITestTypesWrapper,
  ISamplePageImages,
  IResultsWrapperWithCoas,
} from "@/interfaces";
import { MainState, AppNotification } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setLoggedIn(state: MainState, payload: boolean) {
    state.isLoggedIn = payload;
  },
  setLogInError(state: MainState, payload: boolean) {
    state.logInError = payload;
  },
  setUserProfile(state: MainState, payload: IUserProfile) {
    state.userProfile = payload;
  },
  setDashboardMiniDrawer(state: MainState, payload: boolean) {
    state.dashboardMiniDrawer = payload;
  },
  setDashboardShowDrawer(state: MainState, payload: boolean) {
    state.dashboardShowDrawer = payload;
  },
  addNotification(state: MainState, payload: AppNotification) {
    state.notifications.push(payload);
  },
  removeNotification(state: MainState, payload: AppNotification) {
    state.notifications = state.notifications.filter(
      (notification) => notification !== payload,
    );
  },
  setOptions(state: MainState, payload: any) {
    state.options = payload;
  },
  setResults(state: MainState, payload: IResultsWrapper) {
    state.results = payload.results;
    state.resultsPagination = payload.pagination;
  },
  setParentCompanies(state: MainState, payload: IParentCompany[]) {
    state.parentCompanies = payload;
  },
  setOrderId(state: MainState, payload: string | null) {
    state.orderId = payload;
  },
  setSampleId(state: MainState, payload: string | null) {
    state.sampleId = payload;
  },
  setBatchId(state: MainState, payload: string | null) {
    state.batchId = payload;
  },
  setTestId(state: MainState, payload: string | null) {
    state.testId = payload;
  },
  setTestIds(state: MainState, payload: IColumnValuesWrapper) {
    state.testIdsPagination = payload.pagination;
    if (payload.pagination.offset > 0) {
      state.testIds.push(...payload.values);
    } else {
      state.testIds = payload.values;
    }
  },
  setSampleIds(state: MainState, payload: IColumnValuesWrapper) {
    state.sampleIdsPagination = payload.pagination;
    if (payload.pagination.offset > 0) {
      state.sampleIds.push(...payload.values);
    } else {
      state.sampleIds = payload.values;
    }
  },
  setOrderIds(state: MainState, payload: IColumnValuesWrapper) {
    state.orderIdsPagination = payload.pagination;
    if (payload.pagination.offset > 0) {
      state.orderIds.push(...payload.values);
    } else {
      state.orderIds = payload.values;
    }
  },
  setBatchIds(state: MainState, payload: IColumnValuesWrapper) {
    state.batchIdsPagination = payload.pagination;
    if (payload.pagination.offset > 0) {
      state.batchIds.push(...payload.values);
    } else {
      state.batchIds = payload.values;
    }
  },
  setResult(state: MainState, payload: IResult) {
    state.result = payload;
  },
  setSample(state: MainState, payload: ISample) {
    state.sample = payload;
  },
  setShowNavDrawer(state: MainState, payload: boolean) {
    state.showNavDrawer = payload;
  },
  setProductTypes(state: MainState, payload: IColumnValuesWrapper) {
    state.productTypesPagination = payload.pagination;
    if (payload.pagination.offset > 0) {
      state.productTypes.push(...payload.values);
    } else {
      state.productTypes = payload.values;
    }
  },
  setProductType(state: MainState, payload: string | null) {
    state.productType = payload;
  },
  setReportedDates(state: MainState, payload: string[]) {
    state.reportedDates = payload;
  },
  setSampleName(state: MainState, payload: string | null) {
    state.sampleName = payload;
  },
  setSampleNames(state: MainState, payload: IColumnValuesWrapper) {
    state.sampleNamesPagination = payload.pagination;
    if (payload.pagination.offset > 0) {
      state.sampleNames.push(...payload.values);
    } else {
      state.sampleNames = payload.values;
    }
  },
  setTestType(state: MainState, payload: ITestType | null) {
    state.testType = payload;
  },
  setTestTypes(state: MainState, payload: ITestTypesWrapper) {
    state.testTypesPagination = payload.pagination;
    if (payload.pagination.offset > 0) {
      state.testTypes.push(...payload.test_types);
    } else {
      state.testTypes = payload.test_types;
    }
  },
  setSampleResultsOptions(state: MainState, payload: any) {
    state.sampleResultsOptions = payload;
  },
  setSampleResults(state: MainState, payload: IResultsWrapperWithCoas) {
    state.sampleResults = payload.results;
    state.sampleResultsPagination = payload.pagination;
  },
  setSamplePageImages(state: MainState, payload: ISamplePageImages) {
    state.samplePageImages = payload;
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { commit } = getStoreAccessors<MainState | any, State>("");

export const commitSetDashboardMiniDrawer = commit(mutations.setDashboardMiniDrawer);
export const commitSetDashboardShowDrawer = commit(mutations.setDashboardShowDrawer);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
export const commitSetOptions = commit(mutations.setOptions);
export const commitSetResults = commit(mutations.setResults);
export const commitSetParentCompanies = commit(mutations.setParentCompanies);
export const commitSetOrderId = commit(mutations.setOrderId);
export const commitSetSampleId = commit(mutations.setSampleId);
export const commitSetBatchId = commit(mutations.setBatchId);
export const commitSetTestId = commit(mutations.setTestId);
export const commitSetTestIds = commit(mutations.setTestIds);
export const commitSetSampleIds = commit(mutations.setSampleIds);
export const commitSetOrderIds = commit(mutations.setOrderIds);
export const commitSetBatchIds = commit(mutations.setBatchIds);
export const commitSetResult = commit(mutations.setResult);
export const commitSetSample = commit(mutations.setSample);
export const commitSetShowNavDrawer = commit(mutations.setShowNavDrawer);
export const commitSetProductType = commit(mutations.setProductType);
export const commitSetProductTypes = commit(mutations.setProductTypes);
export const commitSetReportedDates = commit(mutations.setReportedDates);
export const commitSetSampleName = commit(mutations.setSampleName);
export const commitSetSampleNames = commit(mutations.setSampleNames);
export const commitSetTestType = commit(mutations.setTestType);
export const commitSetTestTypes = commit(mutations.setTestTypes);
export const commitSetSampleResultsOptions = commit(mutations.setSampleResultsOptions);
export const commitSetSampleResults = commit(mutations.setSampleResults);
export const commitSetSamplePageImages = commit(mutations.setSamplePageImages);
